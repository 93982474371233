import { ActiveOrClosedCard } from './ActiveOrClosedCard';

type Props = {
  status: string;
  disclosureCycleId: string;
};
export const DistributionAndResponseRateCard = ({ status, disclosureCycleId }: Props) => {
  switch (status) {
    case 'active':
    case 'closed': {
      return <ActiveOrClosedCard disclosureCycleId={disclosureCycleId} />;
    }
    default: {
      throw new Error('Unknown disclosure list status');
    }
  }
};
