import router from 'next/router';
import Loading from '@/components/common/loading/Loading';
import { Top5CountriesQuery } from '@/lib/discloser/__generated__/graphql';
import { Routes } from '@/utils/urls';
import { ApolloError } from '@apollo/client';
import { Alert, Box, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import { CardHeader } from './CardHeader';
import { CardLayout } from './CardLayout';

type Props = { data?: Top5CountriesQuery; loading?: boolean; error?: ApolloError };
export const CountryGraphsCard = ({ data, loading, error }: Props) => {
  if (loading) return <Loading />;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  const handleClickButton = async () => {
    await router.push({
      pathname: Routes.Authority.Root,
    });
  };

  return (
    <CardLayout header={<CardHeader handleClickAddOrgsButton={handleClickButton} />}>
      <Stack
        width="100%"
        height="100%"
        bgcolor={theme => theme.palette.background.default}
        gap={2}
        p={2}
      >
        {data?.topCountries
          ?.filter(el => el.countryAlpha2)
          .map((country, index) => (
            <Grid
              container
              width="100%"
              alignItems="center"
              key={index}
              spacing={2}
            >
              <Grid
                item
                xs={1}
              >
                <Box
                  sx={{
                    width: '80%',
                    position: 'relative',
                    top: 0,
                    bottom: 0,
                    left: '10%',
                    height: 'auto',
                  }}
                >
                  {country.countryAlpha2 ? (
                    <img
                      src={`https://flagcdn.com/${country.countryAlpha2.toLowerCase()}.svg`}
                      width={30}
                      alt={country.countryName}
                    />
                  ) : (
                    ''
                  )}
                </Box>
              </Grid>

              <Grid
                item
                xs={10}
              >
                <Typography
                  variant="body2"
                  mb={1}
                >
                  {country.countryName}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  color="secondary"
                  value={country.percentage}
                />
              </Grid>

              <Grid
                item
                xs={1}
              >
                <Typography variant="subtitle2">{country.percentage}%</Typography>
              </Grid>
            </Grid>
          ))}
      </Stack>
    </CardLayout>
  );
};
