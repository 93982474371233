// react
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardRenderer from '@/components/questionnaire/Home/CardRenderer';
import { useQuestionnaireVisibility } from '@/hooks/response/useQuestionnaireVisibility';
import { PhasesEnum } from '@/types/discloser';
// types
import { HomeProgressPoint, QuestionnaireProgressStatuses, ResponseStatusEnum } from '@/types/response';
import { CheckCircleOutlineOutlined, EditOutlined } from '@mui/icons-material';
// mui
import { Box, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
// components
import { DynamicQuestionnaireCtaButton } from './DynamicQuestionnaireCtaButton';
import EditQuestionnaireSetup from './EditQuestionnaireSetupDialog';
import StatusBox from './StatusBox';
// utils
import { useAllHomeProgressPoints } from './utils';

type Props = { phase?: string; responseStatus?: string; isEndOfCycle?: boolean };

export default function StatusInfoBar({ phase, responseStatus, isEndOfCycle }: Props) {
  const { t } = useTranslation([
    'questionnaire',
  ]);
  const [
    showStatus,
    setShowStatus,
  ] = useState<string>(QuestionnaireProgressStatuses.IN_PROGRESS);
  const [
    isConfirmationDialogOpen,
    setIsConfirmationDialogOpen,
  ] = useState(false);
  const { isQuestionnaireClosed } = useQuestionnaireVisibility();

  const handleConfirmationDialogClose = () => setIsConfirmationDialogOpen(false);

  const progressPoints: HomeProgressPoint[] = useAllHomeProgressPoints();

  useEffect(() => {
    switch (phase) {
      case PhasesEnum.REQUEST:
      case PhasesEnum.TRACK:
        setShowStatus(QuestionnaireProgressStatuses.IN_PROGRESS);
        break;
      case PhasesEnum.ANALYSE:
        setShowStatus(QuestionnaireProgressStatuses.SUBMITTED);
        break;
    }
  }, [
    phase,
  ]);

  const isLoading =
    progressPoints?.length === 1 && progressPoints[0].keyLabel === t('questionnaire.home.status.loading');

  return (
    <Stack>
      <CardRenderer sx={{ display: 'flex' }}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={9}
            lg={10}
          >
            <Grid container>
              {isLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <CircularProgress size={20} />
                </Box>
              ) : (
                progressPoints.map(
                  progressPoint =>
                    progressPoint.whenToShow?.includes(showStatus as QuestionnaireProgressStatuses) && (
                      <StatusBox
                        data-testid={`questionnaire-${progressPoint.testId}`}
                        item
                        xs={6}
                        sm={4}
                        md={4}
                        lg={3}
                        xl={2}
                        key={progressPoint.keyLabel}
                        keyLabel={progressPoint.keyLabel}
                        value={progressPoint.value}
                        icon={progressPoint.icon}
                        color={progressPoint.color}
                        linkLabel={progressPoint.linkLabel}
                      />
                    ),
                )
              )}
            </Grid>
          </Grid>
          <DynamicQuestionnaireCtaButton
            item
            xs={12}
            md={3}
            lg={2}
            disabled={isEndOfCycle}
          />
        </Grid>
      </CardRenderer>
      {!isQuestionnaireClosed && responseStatus !== ResponseStatusEnum.SUBMITTED && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: '100%',
            height: 'auto',
            boxSizing: 'border-box',
            paddingY: '5px',
            paddingX: '8px',
            background: theme => theme.palette.common.white,
            borderLeft: '5px solid rgba(0, 93, 180, 0.8)',
          }}
          marginTop={0.5}
        >
          <Stack
            display="flex"
            direction={'row'}
            marginLeft={0.5}
          >
            <CheckCircleOutlineOutlined color="success" />
            <Typography
              variant="body2"
              component="p"
              ml={1}
            >
              {t('questionnaire.editSetUp.completeSetupTitle')}
            </Typography>
          </Stack>

          <IconButton
            aria-label="close"
            size="small"
            onClick={() => {
              setIsConfirmationDialogOpen(true);
            }}
          >
            <EditOutlined />
            <Typography
              variant="subtitle2"
              component="p"
              ml={1}
            >
              {t('questionnaire.editSetUp.editSetupCTA')}
            </Typography>
          </IconButton>
          <EditQuestionnaireSetup
            open={isConfirmationDialogOpen}
            handleClose={handleConfirmationDialogClose}
          />
        </Box>
      )}
    </Stack>
  );
}
