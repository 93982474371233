// i18n
import { useTranslation } from 'next-i18next';
// hooks
import { useProgressMetrics } from '@/hooks/response/useProgressMetrics';
// store
import { QuestionnaireProgressStatuses } from '@/types/response';
// helpers
import { responseStatusIcon } from '@/utils/helpers';
// utils
import { Percent } from '@/utils/numberFormat';
// mui
import { AppsOutlined, ListAltOutlined, TimelapseOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

export function useAllHomeProgressPoints() {
  const { t } = useTranslation('questionnaire', { keyPrefix: 'questionnaire.home' });
  const theme = useTheme();
  const { loading, ...metrics } = useProgressMetrics();
  const { answered, reviewed, skipped, started, unanswered, total, completedSectionNumbers, totalSectionNumbers } =
    metrics;

  if (loading) {
    return [
      {
        whenToShow: [
          QuestionnaireProgressStatuses.IN_PROGRESS,
          QuestionnaireProgressStatuses.SUBMITTED,
          QuestionnaireProgressStatuses.SCORED,
        ],
        keyLabel: t('status.loading'),
        value: '',
        icon: <TimelapseOutlined />,
        color: theme.palette.primary.light,
        testId: 'loading-status-box',
      },
    ];
  }

  return [
    {
      whenToShow: [
        QuestionnaireProgressStatuses.SUBMITTED,
      ],
      keyLabel: t('status.scoreReleaseTime'),
      value: '52 days',
      icon: <TimelapseOutlined />,
      color: theme.palette.primary.light,
      testId: 'scoreReleaseTime-status-box',
    },
    {
      whenToShow: [
        QuestionnaireProgressStatuses.IN_PROGRESS,
        QuestionnaireProgressStatuses.SUBMITTED,
        QuestionnaireProgressStatuses.SCORED,
      ],
      keyLabel: t('status.progressPercentage'),
      value: Percent(((answered + reviewed + skipped) / total) * 100),
      icon: <ListAltOutlined />,
      color: theme.palette.primary.light,
      testId: 'progressPercentage-status-box',
    },
    {
      whenToShow: [
        QuestionnaireProgressStatuses.IN_PROGRESS,
      ],
      keyLabel: t('status.answered'),
      value: answered,
      icon: responseStatusIcon.ANSWERED,
      color: theme.palette.success.light,
      testId: 'answered-status-box',
    },
    {
      whenToShow: [
        QuestionnaireProgressStatuses.IN_PROGRESS,
      ],
      keyLabel: t('status.reviewed'),
      value: reviewed,
      icon: responseStatusIcon.REVIEWED,
      color: theme.palette.success.main,
      testId: 'reviewed-status-box',
    },
    {
      whenToShow: [
        QuestionnaireProgressStatuses.IN_PROGRESS,
      ],
      keyLabel: t('status.skipped'),
      value: skipped,
      icon: responseStatusIcon.SKIPPED,
      color: theme.palette.secondary.dark,
      testId: 'skipped-status-box',
    },
    {
      whenToShow: [
        QuestionnaireProgressStatuses.SUBMITTED,
        QuestionnaireProgressStatuses.SCORED,
      ],
      keyLabel: t('status.submissionDate'),
      value: '02.10.2023',
      icon: <ListAltOutlined />,
      color: theme.palette.primary.light,
      testId: 'submissionDate-status-box',
    },
    {
      whenToShow: [
        QuestionnaireProgressStatuses.SUBMITTED,
        QuestionnaireProgressStatuses.SCORED,
      ],
      keyLabel: t('status.submissionSharedWith'),
      value: 100,
      icon: <ListAltOutlined />,
      color: theme.palette.primary.light,
      testId: 'submissionSharedWith-status-box',
    },

    {
      whenToShow: [
        QuestionnaireProgressStatuses.IN_PROGRESS,
      ],
      keyLabel: t('status.sectionsCompleted'),
      value: completedSectionNumbers + '/' + totalSectionNumbers,
      icon: <AppsOutlined />,
      color: theme.palette.success.main,
      testId: 'sectionsCompleted-status-box',
    },
    {
      whenToShow: [
        QuestionnaireProgressStatuses.IN_PROGRESS,
      ],
      keyLabel: t('status.unanswered'),
      value: unanswered === 0 ? '-' : unanswered,
      icon: responseStatusIcon.UNANSWERED,
      color: theme.palette.text.primary,
      testId: 'unanswered-status-box',
    },
    {
      whenToShow: [
        QuestionnaireProgressStatuses.IN_PROGRESS,
      ],
      keyLabel: t('status.progress'),
      value: started,
      icon: responseStatusIcon.PROGRESS,
      color: theme.palette.warning.main,
      testId: 'progress-status-box',
    },
  ];
}
