import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { CreateDiscloserButton } from '@/components/discloser/Dashboard/CreateDiscloserButton';
import { IconTitle } from '@/components/discloser/Dashboard/IconTitle';
import { AlertCantSubmitList } from '@/components/home/Authority/Alerts/AlertCantSubmitList';
import { EmptyList } from '@/components/home/Authority/types';
import { ListOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { AlertCMInvoiceStatus } from '../Alerts/AlertCMInvoiceStatus';
import { AlertCMRenewalBanner } from '../Alerts/AlertCMRenewalBanner';
import { HomePageLayout } from './HomePageLayout';

type Props = { data: EmptyList };
export const EmptyListView = ({ data }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'common.emptyTile' });
  const { discloserCycleName, currentYear, organisationId } = data;

  return (
    <HomePageLayout
      alerts={
        <>
          <AlertCMInvoiceStatus />
          <AlertCMRenewalBanner organisationId={organisationId} />
          <AlertCantSubmitList viewType={data._tag} />
        </>
      }
    >
      <Stack
        bgcolor="background.default"
        p={2}
      >
        <Stack mb={2}>
          <IconTitle
            icon={<ListOutlined />}
            label={discloserCycleName}
          />
        </Stack>
        <Stack alignItems="center">
          <Image
            src="/images/Add-tasks-bro.svg"
            width={206}
            height={223}
            alt=""
            priority
          />
          <Typography
            variant="h4"
            component="p"
            mt={4}
            mb={1}
            maxWidth="400px"
            align="center"
          >
            {t('title')}
          </Typography>
          <Typography
            variant="body1"
            component="p"
            maxWidth="400px"
            mb={4}
            align="center"
          >
            {t('description', { year: currentYear })}
          </Typography>
          <CreateDiscloserButton adjust_margin={1} />
        </Stack>
      </Stack>
    </HomePageLayout>
  );
};
