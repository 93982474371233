// react
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
// types
import { useQuestionnaireVisibility } from '@/hooks/response/useQuestionnaireVisibility';
// store
import {
  useClauses,
  useDisplayColumns,
  useDisplayRows,
  useExpressions,
  useIsAutoCalcCellsAnswered,
  useRequestingAuthorities,
  useResponses,
  useResponsesInitialized,
} from '@/store/response';
import { NumberOrString, Question, QuestionTypeMatrix, Row } from '@/types/response';
import { useVariableValue } from '@devcycle/nextjs-sdk/pages';
import { GridCellParams } from '@mui/x-data-grid-premium';
import { GridProps } from '../types';
import { isCellAutoCalc, isCellEditable, toDataGridProps } from '../utils';

interface RowProviderProps {
  children: React.ReactNode;
  question: Question;
}

interface RowContextType {
  questionId: NumberOrString;
  data: GridProps;
  rows: Row[];
  loading: boolean;
  isDynamic?: boolean;
  isPrepop: boolean;
  allAutoCalcAnswered: boolean;
  onLoading: (loading: boolean) => void;
  isEditable: (params: GridCellParams) => boolean;
  isAutoCalc: (params: GridCellParams) => boolean;
  isAtleastOneCellDisplayedAndUnlocked: (columnId: string) => boolean;
  submitted: boolean;
}

const RowContext = createContext<RowContextType | undefined>(undefined);

export const useRowLogic = () => {
  const context = useContext(RowContext);
  if (context === undefined) {
    throw new Error('useRowLogic must be used within a RowProvider');
  }
  return context;
};

export const GridViewProvider = ({ children, question }: RowProviderProps) => {
  const [
    data,
    setData,
  ] = useState<GridProps>({ columns: [], rows: [] });

  const [
    loading,
    setLoading,
  ] = useState(false);

  const [
    rowTitle,
    setRowTitle,
  ] = useState(question?.rowTitle);

  const { rows } = useDisplayRows(question.id, (question.type as QuestionTypeMatrix).rows);
  const { columns } = useDisplayColumns(question.id, (question.type as QuestionTypeMatrix).columns);

  const isDynamic = (question.type as QuestionTypeMatrix).isDynamic as boolean;
  const isPrepop = (question.type as QuestionTypeMatrix).isPrepop as boolean;
  const responses = useResponses();
  const expressions = useExpressions();
  const clauses = useClauses();
  const responsesInitialized = useResponsesInitialized();

  const isAmendmentsDeadlineExtension = useVariableValue('amendments-deadline-extension', false);
  const { isQuestionnaireSubmitted, isQuestionnaireClosed } = useQuestionnaireVisibility();
  const isDisabled = isQuestionnaireSubmitted || isQuestionnaireClosed || !isAmendmentsDeadlineExtension;
  const requestingAuthorities = useRequestingAuthorities();

  const allAutoCalcAnswered = useIsAutoCalcCellsAnswered(question.id, rows, columns);

  useEffect(() => {
    if (responsesInitialized) {
      setData(
        toDataGridProps({
          leadingQuestionId: question.id,
          rows: rows,
          columns: columns,
          responses: responses,
          isDynamic: isDynamic,
          submitted: isDisabled,
          authoritiesData: {
            requestingAuthorities: requestingAuthorities,
            rowTitle: rowTitle,
          },
        }),
      );
    }
  }, [
    responsesInitialized,
    JSON.stringify(rows),
    JSON.stringify(columns),
    JSON.stringify(responses),
  ]);

  useEffect(() => {
    setRowTitle(question?.rowTitle);
    if (responsesInitialized)
      setData(
        toDataGridProps({
          leadingQuestionId: question.id,
          rows: rows,
          columns: columns,
          responses: responses,
          isDynamic: isDynamic,
          submitted: isDisabled,
          authoritiesData: {
            requestingAuthorities: requestingAuthorities,
            rowTitle: rowTitle,
          },
        }),
      );
  }, [
    question,
  ]);

  const onLoading = (loading: boolean) => setLoading(loading);

  const isEditable = (params: GridCellParams) => {
    return isCellEditable(params, question.id, expressions, clauses);
  };

  const isAtleastOneCellDisplayedAndUnlocked = (columnId: string): boolean => {
    return rows.some(row => {
      return isCellEditable({ row: { id: row.id }, field: columnId }, question.id, expressions, clauses);
    });
  };

  const isAutoCalc = (params: GridCellParams) => {
    return isCellAutoCalc(params, question.id, expressions);
  };

  const value = useMemo(() => {
    return {
      questionId: question.id,
      data,
      rows,
      isDynamic,
      isPrepop,
      allAutoCalcAnswered,
      loading,
      onLoading,
      isEditable,
      isAutoCalc,
      isAtleastOneCellDisplayedAndUnlocked,
      submitted: isDisabled,
    };
  }, [
    question.id,
    data,
    rows,
    isDynamic,
    isPrepop,
    allAutoCalcAnswered,
    loading,
    onLoading,
    isEditable,
    isAutoCalc,
    isAtleastOneCellDisplayedAndUnlocked,
    isQuestionnaireSubmitted,
    isQuestionnaireClosed,
  ]);

  return <RowContext.Provider value={value}>{children}</RowContext.Provider>;
};
