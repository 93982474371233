export const api_mocking = process.env.NEXT_PUBLIC_API_MOCKING ?? 'disabled';

export const commonConfig = {
  CRMHelpCenterCategoryIDs: {
    categories: {
      addCity: 'a55204f3-fa42-ec11-8c62-6045bd8d96c9',
      questionnaireResponse: '7e8650c8-9443-ec11-8c62-6045bd8d96c9',
      accountsAndAccess: 'a55204f3-fa42-ec11-8c62-6045bd8d96c9',
    },
    subCategories: {
      addCity: '6ba533b8-6f98-ec11-b400-000d3a24db5d',
      extensionRequest: '82f47fa5-d143-ec11-8c60-000d3aae071f',
      disclosurePlatformGuidance: 'b1f47fa5-d143-ec11-8c60-000d3aae071f',
      reportPlatformIssue: '2ef47fa5-d143-ec11-8c60-000d3aae071f',
      makeMyResponsePublic: '3b3df5bf-8898-ec11-b400-000d3a24db5d',
      issuesSubmittingQuestionnaire: '1c638e57-8898-ec11-b400-000d3a24db5d',
      authRequestForDunsInformation: 'd7892ded-03af-ee11-a569-6045bd905d39',
    },
    subjects: {
      addCity: 'Add city',
      extensionRequest: 'Extension request',
      disclosurePlatformGuidance: 'Disclosure platform guidance',
      reportPlatformIssue: 'Report a platform issue',
      makeMyResponsePublic: 'make my response public/non public',
      issuesSubmittingQuestionnaire: 'Issues submitting my questionnaire',
      authRequestForDunsInformation: 'Request for DUNS information',
    },
  },
  welcomeYoutubeVideoEmbedLink: 'https://www.youtube.com/embed/IeT-8UGMuEs?si=tjRQSl8dzh9KZUAR&autoplay=1&mute=1',
  termsLink: 'https://www.cdp.net/en/',
  helpCentreLink: process.env.NEXT_PUBLIC_CRM_HELP_CENTER_URL ?? 'https://help.cdp.net/',
  additionalGuidanceLink: 'https://www.cdp.net/en/guidance_docs',
  reportingGuidanceLink: 'https://www.cdp.net/en/2024-disclosure',
  duplicateDunsLink:
    'https://cdp-dev-crm-csp.powerappsportals.com/en-US/support/create-case/?category=871f1e83-b795-ee11-be37-6045bd905d39&subcategory=d7892ded-03af-ee11-a569-6045bd905d39',
  reportingGuidanceLinkForCity: 'https://www.cdp.net/en/guidance/guidance-for-cities',
  reportingGuidanceLinkForCompany: 'https://www.cdp.net/en/guidance/guidance-for-companies',
  openNewCase: 'https://help.cdp.net/en-US/support/create-case/',
  reportingGuidanceLinkForSubNationalGovernment:
    'https://www.cdp.net/en/states-and-regions#44e9d53857b1b395c1173648a5d98673',
  reportingGuidanceLinkForOther: 'https://www.cdp.net/en/guidance/guidance-for-cities',
  authorityRequests:
    'https://cdn.cdp.net/cdp-production/cms/guidance_docs/pdfs/000/004/964/original/CDP-Industry-Impact-Classification.pdf?1713256538',
  getInTouchLink: 'https://www.cdp.net/en/info/contact',
  campaignLinks: {
    campaign1: 'https://www.cdp.net/en/investor/engage-with-companies/non-disclosure-campaign',
    campaign2: 'https://www.cdp.net/en/investor/engage-with-companies/cdp-science-based-targets-campaign',
  },
  signatoriesFullList: 'https://www.cdp.net/en/investor/signatories-and-members#b796778eacc09c9c2d224e4a6f278ed2',
  cdpHomepage: 'https://www.cdp.net',
  emailTemplatesGuidanceLink: 'https://www.cdp.net/en/guidance_docs?filters%5Bkeyword%5D=email+template&button=',
  eventsAndWebinarsLink: 'https://www.cdp.net/en/events',
  joinAnOrgFormLink: 'https://help.cdp.net/en-US/register-to-disclose',
  whyCantSubmitWhenSubmittedResponse: 'https://help.cdp.net/en-US/register-to-disclose',
  privacyNoticeLink: 'https://www.cdp.net/en/info/privacy-policy',
  feedbackTypeformLink: 'https://cdp-worldwide.typeform.com/to/KoWbk10S',
  termsAndConditionsLink: 'https://www.cdp.net/en/info/terms-and-conditions',
  cmsPaymentTermsAndConditionsLink:
    'https://www.cdp.net/en/info/terms-and-conditions/capital-market-signatory-payments-terms-and-conditions',
  cdpFee: 'https://www.cdp.net/en/info/admin-fee-faq',
  registerAnOrgLink: 'https://help.cdp.net/en-US/register-to-disclose',
  termsOfDisclosureLink: 'https://www.cdp.net/en/info/terms-and-conditions/terms-of-disclosure',
  acsMethodologyLink:
    'https://cdn.cdp.net/cdp-production/cms/guidance_docs/pdfs/000/001/540/original/CDP-ACS-full-list-of-classifications.pdf',
  becomeSelfSelectedCompanyInfoLink: 'https://cdp-pre-crm-csp.powerappsportals.com/en-US/Register-to-Disclose/',
  questionnaireSetupPdfLink:
    'https://cdn.cdp.net/cdp-production/cms/guidance_docs/pdfs/000/004/963/original/preview-of-CDP-corporate-questionnaire-setup.pdf?1711620114',
  guidanceCSTARLink:
    'https://customer-portal-uat.cdpgreenstar.net/guidance/questionnaire?tags=99d675c7-967c-4bda-aa3e-8db239aecb00%2C808c2bbf-92c0-4e42-8c60-e0416be90f2c%2Cfb8844f4-c3bf-44c4-bd73-8c1fc62bf77e%2C5e875522-7950-482f-8b90-dd9a36117fc5%2Cab34269e-70b1-47e2-bdef-d62d903733de%2C06837f5b-8246-4f6f-872e-bf8c0432bd31%2C39c708d0-d227-4b4f-9eed-fe73dcc93e68%2C702109ca-5258-496e-9467-653351f42f08%2Ccaf58167-631f-46af-8710-b619e759ef2f%2C31a3c0e5-5605-498c-8d9e-7961bd525d44%2Ccf09859d-db2e-e911-9112-0050569ce4f2&outputType=QUESTIONNAIRE&type=CSTAR',
  guidanceCityLink: 'https://customer-portal-dev.cdpgreenstar.net/guidance?type=CITIES',
  gridImportExportGuideLink: '#',
};

export const responseConfig = {
  backend_url: process.env.NEXT_PUBLIC_RESPONSE_BACKEND_URL ?? '',
  graphql: `${process.env.NEXT_PUBLIC_RESPONSE_BACKEND_URL ?? ''}/graphql`,
};

export const discloserConfig = {
  backend_url: process.env.NEXT_PUBLIC_DISCLOSURE_BACKEND_URL ?? '',
  graphql: `${process.env.NEXT_PUBLIC_DISCLOSURE_BACKEND_URL ?? ''}/graphql`,
};

export const AzureB2C = {
  tenantName: process.env.NEXT_PUBLIC_AZURE_AD_B2C_TENANT_NAME ?? '',
  clientId: process.env.NEXT_PUBLIC_AZURE_AD_B2C_CLIENT_ID ?? '',
  userFlow: process.env.NEXT_PUBLIC_AZURE_AD_B2C_USER_FLOW ?? '',
  nextAuth: process.env.NEXT_PUBLIC_NEXTAUTH_URL,
  issuer: process.env.NEXT_PUBLIC_AZURE_AD_B2C_ISSUER,
  customDomain: process.env.NEXT_PUBLIC_AZURE_AD_B2C_CUSTOM_DOMAIN,
};

export const applicationEnvironment = process.env.NEXT_PUBLIC_APP_ENV ?? '';

export const MuiX = {
  key:
    process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY ??
    '5be4eba767082164924530ef19e2823cTz03NTMwOCxFPTE3MjcwOTQxMzQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
};
