// react
import { useMemo, useState } from 'react';
// next 18
import { useTranslation } from 'react-i18next';
// components
import { useQuestionnaireVisibility } from '@/hooks/response/useQuestionnaireVisibility';
// store
import { useQuestionResponse, useResponseActions } from '@/store/response';
// types
import { NumberOrString, ResponseAttachment, ResponseStatus } from '@/types/response';
import { useVariableValue } from '@devcycle/nextjs-sdk/pages';
// icons
import CheckIcon from '@mui/icons-material/Check';
import UploadFileIcon from '@mui/icons-material/UploadFile';
// mui
import { Box, Button, IconButton, Link, styled } from '@mui/material';
// data grid
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { AttachementDialog } from './AttachementDialog';
import { ConditionalField } from './ConditionalField';

const StyledBox = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

export const RenderAttachment = (params: GridRenderCellParams<any, any>) => {
  const { t } = useTranslation('questionnaire', {
    keyPrefix: 'questionnaire.response.question.complex',
  });

  const isAmendmentsDeadlineExtension = useVariableValue('amendments-deadline-extension', false);
  const { isQuestionnaireSubmitted, isQuestionnaireClosed } = useQuestionnaireVisibility();
  const isDisabled = isQuestionnaireSubmitted || isQuestionnaireClosed || !isAmendmentsDeadlineExtension;
  const { setResponse } = useResponseActions();
  const [
    open,
    setOpen,
  ] = useState(false);

  const { id: rowId, field: questionId, colDef } = params;
  const leadingQuestionId = (colDef as any).leadingQuestionId as NumberOrString;

  const response = useQuestionResponse({
    questionId,
    leadingQuestionId,
    rowId,
  });

  const attachments = useMemo(
    () => response?.attachments ?? [],
    [
      response,
    ],
  );

  const handleChange = (status: ResponseStatus, attachments?: ResponseAttachment[]) => {
    setResponse({
      questionId,
      leadingQuestionId,
      rowId,
      content: undefined,
      attachments,
      status,
    });
  };

  const handleClick = () => setOpen(true);

  const content =
    attachments?.length > 0 ? (
      <Button
        color="secondary"
        startIcon={<CheckIcon color="success" />}
        onClick={handleClick}
        variant="text"
        disabled={isDisabled}
        sx={{ opacity: isDisabled ? 0.5 : 1 }}
      >
        <Link
          underline="always"
          href="#"
          color="secondary"
        >
          {t('file', { attachments: Number(attachments.length), count: Number(attachments.length) })}
        </Link>
      </Button>
    ) : (
      <IconButton
        onClick={handleClick}
        disabled={isDisabled}
        sx={{ opacity: isDisabled ? 0.5 : 1 }}
      >
        <UploadFileIcon />
      </IconButton>
    );

  return (
    <>
      <ConditionalField params={params}>
        <StyledBox>{content}</StyledBox>
      </ConditionalField>
      <AttachementDialog
        open={open}
        attachments={attachments}
        questionId={questionId}
        rowId={rowId}
        onClose={() => setOpen(false)}
        onChange={handleChange}
      />
    </>
  );
};
