// next
import Head from 'next/head';
// react
import { PropsWithChildren } from 'react';
// components
import Nav from '@/components/nav';
import { useStore } from '@/store/authorityType';
import { useRequesterView } from '@/store/response';
// mui
import { Box, BoxProps } from '@mui/material';

type Props = PropsWithChildren<{ title?: string }> & BoxProps;
export const Layout = ({ children, title = 'CDP', sx }: Props) => {
  const { authorityTypeId } = useStore();
  const { isRequesterView } = useRequesterView();

  return (
    <Box key={authorityTypeId}>
      <Head>
        <title>{title}</title>
      </Head>
      <Box
        sx={{
          display: 'flex',
          minHeight: 1,
          background: theme => theme.palette.background.paper,
        }}
      >
        {!isRequesterView && <Nav />}
        <Box
          component="main"
          sx={{ minWidth: 0, minHeight: '100vh', flexGrow: 1, ...sx }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
