import { DistributionAndResponseRateCard } from '@/components/discloser/Dashboard/DistributionAndResponseRateCard';
import { CountryGraphsCard } from '@/components/discloser/Dashboard/DistributionAndResponseRateCard/CountryGraphsCard';
import { GET_TOP_5_COUNTRIES } from '@/graphql/disclosure/queries';
import { Top5CountriesQuery } from '@/lib/discloser/__generated__/graphql';
import { useStore } from '@/store/authorityType';
import { disclosureClient } from '@/utils/apollo-client';
import { formatDate } from '@/utils/date';
import { onApolloError } from '@/utils/errorFormat';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { DataAndInsightsTile } from '../../DataAndInsightsTile/DataAndInsightsTile';
import { KPICard, List } from '../../KPICard';
import { AlertCMInvoiceStatus } from '../Alerts/AlertCMInvoiceStatus';
import { AlertCMRenewalBanner } from '../Alerts/AlertCMRenewalBanner';
import { LatestCampaigns, SupportGrid } from '../HomeTiles';
import { PreviousList } from '../types';
import { HomePageLayout } from './HomePageLayout';

type Props = { data: PreviousList };
export const PreviousListView = ({ data }: Props) => {
  const {
    prevListYear,
    prevListDeadline,
    prevListSubmitDueDate,
    invitedOrganisationsCount,
    submittedResponseRate,
    status,
    totalOrganisationCount,
    disclosureCycleId,
    discloserListId,
    organisationId,
  } = data;

  const kpiCardData: List = {
    _tag: 'List',
    submitDueDate: formatDate(prevListSubmitDueDate, 'MMM d'),
    deadlineToDisclose: formatDate(prevListDeadline, 'MMM d'),
    organisationCount: invitedOrganisationsCount,
    year: prevListYear,
    submittedResponseRate,
    discloserListId,
    status,
  };

  const { authorityTypeId } = useStore();
  const {
    data: countryData,
    loading,
    error,
  } = useQuery<Top5CountriesQuery>(GET_TOP_5_COUNTRIES, {
    variables: {
      disclosureCycleId,
      authorityTypeId,
    },
    fetchPolicy: 'network-only',
    client: disclosureClient,
    onError: onApolloError,
  });

  const shouldShowDistributionTile = !(
    status === 'pending' &&
    (totalOrganisationCount === 0 || countryData?.topCountries.length === 0)
  );

  return (
    <HomePageLayout
      alerts={
        <>
          <AlertCMInvoiceStatus />
          <AlertCMRenewalBanner organisationId={organisationId} />
        </>
      }
    >
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
      >
        <Grid
          item
          md={12}
        >
          <KPICard data={kpiCardData} />
        </Grid>
        {shouldShowDistributionTile && (
          <Grid
            item
            md={6}
            sx={{ display: 'flex', width: '100%' }}
          >
            <Grid
              item
              sx={{ width: '100%' }}
            >
              {status === 'pending' ? (
                <CountryGraphsCard
                  data={countryData}
                  loading={loading}
                  error={error}
                />
              ) : (
                <DistributionAndResponseRateCard
                  status={status}
                  disclosureCycleId={disclosureCycleId}
                />
              )}
            </Grid>
          </Grid>
        )}
        <Grid
          item
          sm={12}
          md={shouldShowDistributionTile ? 6 : 12}
        >
          <SupportGrid flexRow={!shouldShowDistributionTile} />
        </Grid>
        <Grid
          item
          md={6}
        >
          <DataAndInsightsTile />
        </Grid>
        <Grid
          item
          md={6}
        >
          <LatestCampaigns />
        </Grid>
      </Grid>
    </HomePageLayout>
  );
};
