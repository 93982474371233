import { rest } from 'msw';

// This has an impact on the progress metrics displayed in the questionnaire
//  customer_portal/hooks/response/useProgressMetrics.ts
// if (overallProgress.total === 0 && statisticsData) {
//      return statisticsData;
// }

const statisticsResponse = {
  instanceId: 755,
  createdOn: '2024-06-12T10:42:25.684Z',
  updatedOn: '2025-01-10T09:36:59.456Z',
  isDeleted: false,
  total: 118,
  answered: 23,
  unanswered: 66,
  skipped: 1,
  inProgress: 28,
  reviewed: 0,
  completedSections: 0,
  totalSectionNumbers: 11,
  reportedOn: '2025-01-10T09:40:00.027Z',
  submissionDate: '2025-01-10T09:40:00.027Z',
};

export const questionnaireStatisticsMockHandlers = [
  rest.get(`${process.env.NEXT_PUBLIC_RESPONSE_BACKEND_URL}/questionnaire/portal/statistics`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(statisticsResponse));
  }),
];
