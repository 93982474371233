// next
// next auth
import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
// react
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// components
import LoadingScreen from '@/components/loading-screen';
// pages
import SignInPage from '@/pages/signin';
// store
import { useStore } from '@/store/authorityType';
import { AuthorityTypeIdService } from '@/types/authority.type.id.service';
// routing
import { Routes } from '@/utils/urls';
// utils
import { setSession } from './utils';

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { data: session, status } = useSession();
  const router = useRouter();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const authorityType = useStore();

  useEffect(() => {
    if (session?.error === 'RefreshAccessTokenError') signIn(); // force sign in to hopefully resolve error
  }, [
    session,
  ]);

  useEffect(() => {
    const isInAdminPath = router.asPath.includes(Routes.Admin.Root);
    const isInSettingsPath = router.asPath.includes(Routes.Account);
    const isInScenarioTestingPath = router.asPath.includes(Routes.Questionnaire.ScenarioTesting);
    const isInCMSForbiddenPath =
      router.pathname.includes('/[discloserListId]') &&
      !router.pathname.includes('samples') &&
      !router.pathname.includes('tracking-portfolios');
    const isRequesterViewPath = router.pathname.includes(Routes.Questionnaire.Review);

    if (
      !isInAdminPath &&
      !isInSettingsPath &&
      !isInScenarioTestingPath &&
      !isRequesterViewPath &&
      session?.user?.isCDPAdmin &&
      !session?.user?.extension_OrganisationId
    ) {
      router.replace(Routes.Admin.Root);
    } else if (isInAdminPath && session?.user?.isCDPAdmin && !!session?.user?.extension_OrganisationId) {
      router.replace(Routes.Home);
    } else if (isInAdminPath && !session?.user?.isCDPAdmin && session?.user?.extension_OrganisationId !== undefined) {
      router.replace(Routes.Home);
    } else if (
      isInCMSForbiddenPath &&
      authorityType.authorityTypeId === AuthorityTypeIdService.CAPITAL_MARKETS_SIGNATORY() &&
      session?.user?.extension_OrganisationId !==
        (process.env.NEXT_PUBLIC_CDP_CAPITAL_MARKETS_ORGANISATION_ID as string)
    ) {
      router.replace(Routes.Home);
      enqueueSnackbar(t('common.authGuardCMS'), {
        variant: 'error',
      });
    }
  }, [
    session,
    router,
    authorityType,
  ]);

  if (status === 'loading') return <LoadingScreen />;

  if (status === 'unauthenticated') return <SignInPage callbackUrl={router.asPath} />;

  if (session?.accessToken) setSession(session.accessToken);

  return <>{children}</>;
}
