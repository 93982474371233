// utils
import { GetServerSideProps } from 'next';
import { getSession } from 'next-auth/react';
// components
import { Home } from '@/components/questionnaire/Home/Home';
// layouts
import MainLayout from '@/layouts/main/MainLayout';
import { getServerSideDevCycle } from '@/utils/featureFlag';
import { getServerSideTranslations } from '@/utils/i18n';

const Page = () => {
  return <Home />;
};

Page.getLayout = (page: React.ReactElement) => (
  <MainLayout
    className="main-layout"
    sx={{ padding: 0 }}
  >
    {page}
  </MainLayout>
);

export default Page;

export const getServerSideProps: GetServerSideProps = async context => {
  const session = await getSession(context);
  try {
    return {
      props: {
        ...(await getServerSideTranslations(context.locale, [
          'questionnaire',
        ])),
        ...(await getServerSideDevCycle(session, context)),
      },
    };
  } catch {
    return { notFound: true };
  }
};
