import { formatDate } from '@/utils/date';
import { Grid } from '@mui/material';
import { DataAndInsightsTile } from '../../DataAndInsightsTile/DataAndInsightsTile';
import { KPICard, NoList as NoListType } from '../../KPICard';
import { AlertCMInvoiceStatus } from '../Alerts/AlertCMInvoiceStatus';
import { AlertCMRenewalBanner } from '../Alerts/AlertCMRenewalBanner';
import { AlertCantSubmitList } from '../Alerts/AlertCantSubmitList';
import { LatestCampaigns, SupportGrid } from '../HomeTiles';
import { NoList } from '../types';
import { HomePageLayout } from './HomePageLayout';

type Props = { data: NoList };
export const NoListView = ({ data }: Props) => {
  const { nextListDeadline, nextListSubmitDueDate, nextListYear, organisationId } = data;

  const kpiCardData: NoListType = {
    _tag: 'NoList',
    year: nextListYear,
    submitDueDate: formatDate(nextListSubmitDueDate, 'MMM d'),
    deadlineToDisclose: formatDate(nextListDeadline, 'MMM d'),
  };

  return (
    <HomePageLayout
      alerts={
        <>
          <AlertCMInvoiceStatus />
          <AlertCMRenewalBanner organisationId={organisationId} />
          <AlertCantSubmitList viewType={data._tag} />
        </>
      }
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={12}
        >
          <KPICard data={kpiCardData} />
        </Grid>
        <Grid
          item
          md={12}
        >
          <SupportGrid />
        </Grid>
        <Grid
          item
          md={6}
        >
          <DataAndInsightsTile />
        </Grid>
        <Grid
          item
          md={6}
        >
          <LatestCampaigns />
        </Grid>
      </Grid>
    </HomePageLayout>
  );
};
