import {
  GetDelegatedRequestsMock,
  MyRequestsMock,
  MyRequestsTotalRequestersMock,
  addDiscloserListOrganisationsToTrackingPortfolioMock,
  addDiscloserMock,
  addOrganisationToProjectInstanceMock,
  checkIfCanBeScoredMock,
  checkIfOrgsInTheListMock,
  checkIfOrgsInTheProjectMock,
  createDiscloserListMock,
  createInvitationMock,
  createProjectMock,
  createQuestionnaireSetupMock,
  createTrackingPortfolioMock,
  createUserWithInviteMock,
  delegateRequestMock,
  deleteDemoListMock,
  deleteOrganisationsFromSampleListMock,
  deleteProjectInstanceMock,
  deleteProjectMock,
  deleteSampleMock,
  deleteTrackingPortfolioMock,
  disableUserMock,
  getActiveDisclosureCycleForDiscloserMock,
  getActiveDisclosureCycleMock,
  getActiveRequestersMock,
  getAllDiscloserListsMock,
  getAllIndustriesMock,
  getAllTagCollectionsMock,
  getAllTagsMock,
  getAuthorityTypesMock,
  getAvailableRolesMock,
  getCDPAdminUsersMock,
  getCommoditiesMock,
  getCopiableDiscloserListsMock,
  getDiscloserLateRequestsMock,
  getDiscloserListExportFileLinkMock,
  getDiscloserListFilterOptionsMock,
  getDiscloserListForCycleMock,
  getDiscloserListTrackingPortfoliosMock,
  getDiscloserMock,
  getDisclosingOrganisationMock,
  getDisclosureCycleByIdMock,
  getExistingProjectRequestsMock,
  getExistingProjectRequestsNumberMock,
  getExtendedOrgSearchMock,
  getFutureDisclosureCyclesMock,
  getImportListReportsMock,
  getImportListTemplateMock,
  getImportedProjectInstanceOrganisationsMock,
  getInviteByJwtMock,
  getLateRequestsTotalRequestsMock,
  getMyDetailsMock,
  getMyOrgCountryResponseMock,
  getMyOrganisationDetailsMock,
  getNextDisclosureCycleForDiscloserMock,
  getNextDisclosureCycleMock,
  getOptionalProjectRequestsMock,
  getOrgResponseByCycleMock,
  getOrganisationAccountManagerMock,
  getOrganisationAuthorityTypesMock,
  getOrganisationFilterOptionsMock,
  getOrganisationInvoiceMock,
  getOrganisationMock,
  getOrganisationUsersMock,
  getPhasesInformationMock,
  getProjectByIdMock,
  getProjectInstanceByIdMock,
  getProjectInstanceOrganisationsMock,
  getProjectInstanceSnapshotMock,
  getProjectInstancesByAuthorityMock,
  getProjectInstancesMock,
  getProjectsMock,
  getQuestionnaireSetupByOrgIdMock,
  getQuestionnaireSetupMock,
  getRequestersMock,
  getResponseByOrganisationIdMock,
  getResponseRateStatsMock,
  getSampleByAuthorityTypeIdMock,
  getSampleCdpAuthorityMock,
  getSampleMock,
  getSampleOrganisationsMock,
  getSampleResponseRateMock,
  getSearchOrganisationMock,
  getSubsidiaryRequestDetailsMock,
  getTagExportFileLinkMock,
  getTodosMock,
  getTopCountriesForSampleMock,
  getTopCountriesMock,
  getTopIndustriesForSampleMock,
  getTopIndustriesMock,
  getTrackingPorfolioByIdMock,
  getTrackingPortfolioExportFileLinkMock,
  getUserTasksMock,
  joinOptionalProjectMock,
  mySubsidiaryRequestsMock,
  publishProjectInstanceMock,
  publishSampleMock,
  removeDiscloserLateRequestFromListMock,
  removeDiscloserMock,
  removeOrganisationsFromProjectInstanceMock,
  resetListMock,
  searchCountryMock,
  setResponseMappingMock,
  submitDiscloserMock,
  top5CountriesMock,
  unpublishProjectInstanceMock,
  unpublishSampleMock,
  updateAuthorityReferenceMock,
  updateCommoditiesMock,
  updateDiscloserEmailMock,
  updateDiscloserListThemesMock,
  updateMyLanguagePreferencesMock,
  updateOrgDetailsMock,
  updateProjectMock,
  updateQuestionnaireSetupMock,
  updateResponseExtensionMock,
  updateSampleMock,
  updateUserDetailsMock,
} from './responses';

export const discloserMocks = [
  getDisclosureCycleByIdMock,
  getQuestionnaireSetupByOrgIdMock,
  getExistingProjectRequestsNumberMock,
  getMyOrgCountryResponseMock,
  getOrganisationMock,
  getAllDiscloserListsMock,
  getDiscloserMock,
  getUserTasksMock,
  getSampleResponseRateMock,
  addDiscloserMock,
  removeDiscloserMock,
  submitDiscloserMock,
  getSearchOrganisationMock,
  getCopiableDiscloserListsMock,
  searchCountryMock,
  getAllIndustriesMock,
  checkIfOrgsInTheListMock,
  getActiveDisclosureCycleMock,
  getDiscloserListForCycleMock,
  resetListMock,
  delegateRequestMock,
  setResponseMappingMock,
  getTopCountriesMock,
  getTopIndustriesMock,
  getDiscloserLateRequestsMock,
  createDiscloserListMock,
  getImportListReportsMock,
  getImportListTemplateMock,
  checkIfOrgsInTheProjectMock,
  getDiscloserListFilterOptionsMock,
  updateAuthorityReferenceMock,
  getOrganisationFilterOptionsMock,
  getMyOrganisationDetailsMock,
  updateOrgDetailsMock,
  getDiscloserListExportFileLinkMock,
  checkIfCanBeScoredMock,
  getMyDetailsMock,
  getResponseRateStatsMock,
  MyRequestsMock,
  GetDelegatedRequestsMock,
  mySubsidiaryRequestsMock,
  getSubsidiaryRequestDetailsMock,
  getAllTagCollectionsMock,
  getTagExportFileLinkMock,
  createTrackingPortfolioMock,
  getDiscloserListTrackingPortfoliosMock,
  getTrackingPortfolioExportFileLinkMock,
  getTrackingPorfolioByIdMock,
  deleteTrackingPortfolioMock,
  createProjectMock,
  getProjectsMock,
  getOrganisationUsersMock,
  getAvailableRolesMock,
  createInvitationMock,
  updateUserDetailsMock,
  disableUserMock,
  getProjectByIdMock,
  updateProjectMock,
  deleteProjectMock,
  getInviteByJwtMock,
  createUserWithInviteMock,
  getNextDisclosureCycleMock,
  getFutureDisclosureCyclesMock,
  getCDPAdminUsersMock,
  getAllTagsMock,
  getAuthorityTypesMock,
  getProjectInstancesMock,
  getProjectInstanceByIdMock,
  deleteProjectInstanceMock,
  publishProjectInstanceMock,
  unpublishProjectInstanceMock,
  getProjectInstancesByAuthorityMock,
  addOrganisationToProjectInstanceMock,
  removeOrganisationsFromProjectInstanceMock,
  updateMyLanguagePreferencesMock,
  getSampleMock,
  getSampleOrganisationsMock,
  createQuestionnaireSetupMock,
  getProjectInstanceOrganisationsMock,
  getProjectInstanceSnapshotMock,
  createQuestionnaireSetupMock,
  updateQuestionnaireSetupMock,
  getActiveDisclosureCycleForDiscloserMock,
  getQuestionnaireSetupMock,
  getPhasesInformationMock,
  addDiscloserListOrganisationsToTrackingPortfolioMock,
  getTopCountriesForSampleMock,
  getTopIndustriesForSampleMock,
  getSampleByAuthorityTypeIdMock,
  getSampleCdpAuthorityMock,
  publishSampleMock,
  unpublishSampleMock,
  deleteSampleMock,
  deleteOrganisationsFromSampleListMock,
  getExtendedOrgSearchMock,
  updateSampleMock,
  updateDiscloserListThemesMock,
  updateDiscloserEmailMock,
  getTodosMock,
  getOrganisationAccountManagerMock,
  getCommoditiesMock,
  updateCommoditiesMock,
  getOrganisationAuthorityTypesMock,
  getNextDisclosureCycleForDiscloserMock,
  getExistingProjectRequestsMock,
  getOptionalProjectRequestsMock,
  getImportedProjectInstanceOrganisationsMock,
  getOrganisationInvoiceMock,
  joinOptionalProjectMock,
  top5CountriesMock,
  getDisclosingOrganisationMock,
  getRequestersMock,
  getActiveRequestersMock,
  getResponseByOrganisationIdMock,
  getOrgResponseByCycleMock,
  MyRequestsTotalRequestersMock,
  updateResponseExtensionMock,
  getLateRequestsTotalRequestsMock,
  removeDiscloserLateRequestFromListMock,
  deleteDemoListMock,
];
