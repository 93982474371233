import { useTranslation } from 'next-i18next';
import { Link, Stack, Typography } from '@mui/material';

type Props = {
  name: string;
  email: string;
};
export const AccountManagement = ({ name, email }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'common.accountManagementTile' });

  return (
    <Stack
      display="flex"
      bgcolor="background.default"
      p={2}
      sx={{ overflowWrap: 'break-word' }}
    >
      <Typography
        variant="subtitle1"
        component="p"
      >
        {t('title')}
      </Typography>
      <Typography
        variant="body2"
        component="p"
        mt={1}
      >
        {t('description')}
      </Typography>
      <Stack
        sx={{
          borderLeft: '2px solid #A4BAC2', // TODO: [wnguyen] fix when design system is finalised
          px: 2,
          mt: 4,
        }}
      >
        <Typography
          variant="caption"
          component="p"
        >
          <b>{name}</b>
        </Typography>
        <Typography
          variant="caption"
          component="p"
          sx={{
            overflowWrap: 'break-word',
          }}
        >
          <Link
            variant="caption"
            href={`mailto:${email}`}
            color="text.primary"
          >
            {email}
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
};
