import { useTranslation } from 'next-i18next';
import { GET_ORGANISATION_ACCOUNT_MANAGER } from '@/graphql/disclosure/queries';
import { GetOrganisationAccountManagerQuery } from '@/lib/discloser/__generated__/graphql';
import { useStore } from '@/store/authorityType';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { useQuery } from '@apollo/client';
import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { AccountManagement } from './AccountManagement';
import { EmailTemplates } from './EmailTemplates';
import { LatestGuidance } from './LatestGuidance';
import { LatestWebinar } from './LatestWebinar';

type Props = {
  flexRow?: boolean;
};

export const SupportGrid = ({ flexRow = true }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'common' });
  const { authorityTypeId } = useStore();

  const { data, loading } = useQuery<GetOrganisationAccountManagerQuery>(GET_ORGANISATION_ACCOUNT_MANAGER, {
    variables: { authorityTypeId },
    client: disclosureClient,
    onError: onApolloError,
  });

  const accData = data?.getOrganisationAccountManager;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const flexStyle = {
    display: 'flex',
    flexDirection: `${flexRow ? 'row' : 'column'}`,
    gap: 2,
    flexWrap: `${flexRow && smallScreen ? 'wrap' : 'noWrap'}`,
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        flexDirection: { sm: 'column', md: 'row' },
        display: 'flex',
        height: 'auto',
        minHeight: '100%',
      }}
    >
      {/* left */}
      <Grid
        item
        sm={12}
        lg={6}
        sx={{
          ...flexStyle,
          width: '100%',
        }}
      >
        <Grid
          item
          sm={12}
        >
          <EmailTemplates />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <LatestGuidance />
        </Grid>
      </Grid>
      {/* right */}
      <Grid
        item
        sm={12}
        lg={6}
        sx={{
          ...flexStyle,
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      >
        {loading ? (
          <Skeleton aria-label={t('loading')} />
        ) : (
          accData && (
            <Grid
              item
              xs={12}
            >
              <AccountManagement
                name={`${accData.firstName} ${accData.lastName}`}
                email={accData.emailAddress}
              />
            </Grid>
          )
        )}
        <Grid
          item
          xs={12}
          sx={{
            flexGrow: 1,
            minHeight: 0,
            '& > *': {
              height: '100%',
              width: '100%',
            },
          }}
        >
          <LatestWebinar />
        </Grid>
      </Grid>
    </Grid>
  );
};
