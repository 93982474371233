// types
import { isEmpty, sortBy } from 'lodash';
// zustand
import { create } from 'zustand';
import {
  getVisibleColumns,
  getVisibleRows,
  isMatrixTableAndMandatoryColumnsAreNotFullyAnswered,
  isMatrixTableVariant,
  isQuestionTypeMatrix,
} from '@/components/questionnaire/Response/Question/GridView/utils';
import {
  ClauseKind,
  ContentBlock,
  CrossValidation,
  ExportFormat,
  ExportOption,
  ExportQuestionnaire,
  Expression,
  GroupedOption,
  Ids,
  LocalResponse,
  NumberOrString,
  Option,
  Progress,
  ProgressStatus,
  Question,
  QuestionClause,
  QuestionResponse,
  QuestionTypeMatrix,
  Questionnaire,
  QuestionnaireStatus,
  RequesterView,
  ResponseStatus,
  ResponseStore,
  ResponsesQuery,
  Row,
  Section,
  SectionType,
  SubmissionStep,
  UncompletedSubmission,
} from '@/types/response';
import { toClauses } from '@/utils/clauses';
import { evaluateCrossValidation, toCrossValidation } from '@/utils/crossValidation';
import { evaluatePostfixExpression, toExpressions } from '@/utils/expressions';
import { requestingAuthoritiesList } from '@/utils/requestingAuthorities';
// utils
import {
  checkAllAnswered,
  cleanAfterRowDeletion,
  findQuestionById,
  getComplexQuestionStatus,
  initProgressPoints,
  initializeStore,
  keyFromIds,
  processOptionResponse,
  toResponses,
  updateAfterRowAddition,
  updateComplexResponse,
  updateComplexResponseInPlace,
  updateErrorFields,
  updateProgress,
  updateResponse,
  updateResponseInPlace,
} from '@/utils/response';

// https://tkdodo.eu/blog/working-with-zustand

const useResponseStore = create<ResponseStore>(set => ({
  requestingAuthorities: [],
  clausesPreview: [],
  metadata: undefined,
  expressionsPreview: [],
  questionnaire: undefined,
  dynamicRowMapping: new Map<NumberOrString, { id: string; name: string }[]>(),
  staticRowMapping: new Map<NumberOrString, { id: string; name: string }[]>(),
  questionsNumbers: new Map<NumberOrString, NumberOrString>(),
  responses: {},
  responsesInitialized: false,
  currentSectionIdx: 0,
  currentQuestionIdx: 0,
  currentGuidanceQuestionIdx: 0,
  progress: {
    questionnaire: initProgressPoints(),
    sections: {},
    complexQuestions: {},
    sectionMap: {},
  },
  defaultCurrency: 'USD',
  saving: 'idle',
  lastSaved: undefined,
  dirtyFields: {},
  errorFields: {},
  clauses: new Map<NumberOrString, QuestionClause>(),
  clausesFetched: [],
  disableCL: false,
  isCalculatingCL: false,
  importGridErrors: new Map(),
  availableTemplates: new Set(),
  exportQuestionnaire: {
    step: 0,
    format: ExportFormat.WORD,
    option: ExportOption.ALL,
    sections: new Set(),
    exporting: false,
  },
  // auto calculted fields
  expressions: new Map<NumberOrString, NumberOrString[]>(),
  expressionsFetched: [],
  // submission journey
  submission: {
    step: SubmissionStep.QUESTIONNAIRE,
    isPublic: true,
  },
  isToInternetConnected: true,

  idSet: new Set<NumberOrString>(),
  skipSave: false,
  isGuidance: false,
  blocksByQuestion: new Map<string, ContentBlock[]>(),
  // cross validation,
  crossValidation: new Map<string, CrossValidation>(),
  // separate "namespace" for actions
  lastChangesHash: '',
  requesterView: {
    isRequesterView: false,
    discloserId: '',
    disclosureCycleId: '',
  },

  actions: {
    setRequestingAuthorities: data =>
      set(() => {
        return {
          requestingAuthorities: requestingAuthoritiesList(data),
        };
      }),

    setQuestionnaire: (questionnaire: Questionnaire) => set(() => ({ questionnaire })),
    setQuestionsNumbers: (questionsNumbers: Map<NumberOrString, NumberOrString>) =>
      set(() => ({
        // ...state,
        questionsNumbers: questionsNumbers,
      })),
    setSkipSave: skipSave => set(() => ({ skipSave })),
    setGuidance: isGuidance => set(() => ({ isGuidance })),
    setMetadata: metadata => set(() => ({ metadata })),
    setAvailableTemplates: (questionIds: string[]) => set(() => ({ availableTemplates: new Set(questionIds) })),
    setCrossValidation: data =>
      set(state => {
        return {
          crossValidation: toCrossValidation(data, state.idSet),
        };
      }),
    setBlockByQuestion: blocksByQuestion =>
      set(() => ({
        blocksByQuestion,
      })),
    setResponsesInitialized: () => set(() => ({ responsesInitialized: true })),
    setResponse: (value: LocalResponse) => {
      set((state: ResponseStore) => {
        if (state.isGuidance) return state;
        if (value.leadingQuestionId && value.rowId) {
          const { leadingQuestionId, rowId, questionId } = value;
          const key = keyFromIds(leadingQuestionId, rowId, questionId);

          // update child question progress
          const progress = updateProgress(state.progress, value);

          const responses = {
            ...state.responses,
            [key]: {
              ...value,
            },
            // update leading question status
            [leadingQuestionId]: {
              content: null,
              status: getComplexQuestionStatus(leadingQuestionId, progress.complexQuestions, state.clauses).status,
              questionId: leadingQuestionId,
            },
          };

          const { errorFields } = updateErrorFields(state.errorFields, value.errorMessages, [
            leadingQuestionId,
            key,
          ]);

          return {
            responses,
            dirtyFields: {
              ...state.dirtyFields,
              [key]: true,
              [leadingQuestionId]: true,
            },
            errorFields,
            // update parent question progress
            progress: updateProgress(progress, responses[leadingQuestionId]),
          };
        } else {
          const progress = updateProgress(state.progress, value);
          const { errorFields } = updateErrorFields(state.errorFields, value.errorMessages, [
            value.questionId,
          ]);
          return {
            responses: {
              ...state.responses,
              [value.questionId]: {
                ...value,
              },
            },
            progress,
            dirtyFields: {
              ...state.dirtyFields,
              [value.questionId]: true,
            },
            errorFields,
          };
        }
      });
    },

    setResponseBatch: (values: LocalResponse[]) => {
      set((originalState: ResponseStore) => {
        const state = {
          responses: { ...originalState.responses },
          progress: { ...originalState.progress },
          dirtyFields: { ...originalState.dirtyFields },
          errorFields: { ...originalState.errorFields },
          clauses: originalState.clauses,
          isGuidance: originalState.isGuidance,
        };

        for (const value of values) {
          if (state.isGuidance) return state;
          if (value.leadingQuestionId && value.rowId) {
            const { leadingQuestionId, rowId, questionId } = value;
            const key = keyFromIds(leadingQuestionId, rowId, questionId);

            // update child question porogress
            const progress = updateProgress(state.progress, value);
            state.responses[key] = { ...value };
            state.responses[leadingQuestionId] = {
              content: null,
              status: getComplexQuestionStatus(leadingQuestionId, progress.complexQuestions, state.clauses).status,
              questionId: leadingQuestionId,
            };

            const { errorFields } = updateErrorFields(state.errorFields, value.errorMessages, [
              leadingQuestionId,
              key,
            ]);
            state.dirtyFields[key] = true;
            state.dirtyFields[leadingQuestionId] = true;
            state.errorFields = errorFields;
            state.progress = updateProgress(progress, state.responses[leadingQuestionId]);
          } else {
            const progress = updateProgress(state.progress, value);
            const { errorFields } = updateErrorFields(state.errorFields, value.errorMessages, [
              value.questionId,
            ]);
            state.responses[value.questionId] = { ...value };
            state.progress = progress;
            state.dirtyFields[value.questionId] = true;
            state.errorFields = errorFields;
          }
        }
        return {
          responses: state.responses,
          progress: state.progress,
          dirtyFields: state.dirtyFields,
          errorFields: state.errorFields,
        };
      });
    },

    setResponses: (data: ResponsesQuery['responses']) => {
      set(state => {
        console.log('setting responses', data.responses);
        const { responses, progress, dirtyFields } = toResponses(data, state.progress, state.clauses, state.idSet);
        state.dirtyFields = { ...state.dirtyFields, ...dirtyFields };
        return {
          ...state,
          responses,
          progress,
          responsesInitialized: true,
        };
      });
    },

    setCurrentSection: (idx: number) =>
      set(() => ({
        currentSectionIdx: idx,
        currentGuidanceQuestionIdx: 0,
        currentQuestionIdx: 0,
      })),

    setClause: entry =>
      set(state => {
        const { key, value } = entry;
        const { leadingQuestionId, rowId, questionId, optionId, kind, display } = value;

        let responses = { ...state.responses };
        let dirtyFields = { ...state.dirtyFields };
        let progress = { ...state.progress };
        const clauses = new Map(state.clauses);

        clauses.set(key, value);

        if (kind === ClauseKind.ChildQuestion && leadingQuestionId) {
          const key = keyFromIds(leadingQuestionId, rowId, questionId);

          responses = updateResponse(responses, display, key);
          progress = updateProgress(progress, responses[key]);
          dirtyFields = { ...dirtyFields, [key]: true };

          const leadingQuestiondisplay = clauses.get(leadingQuestionId)?.display;

          // update parent question
          responses = updateComplexResponse(responses, clauses, progress, leadingQuestionId, leadingQuestiondisplay);
          dirtyFields = {
            ...dirtyFields,
            [leadingQuestionId]: true,
          };
          progress = updateProgress(progress, responses[leadingQuestionId]);
        }

        if (kind === ClauseKind.Column && leadingQuestionId) {
          const rows = Object.keys(progress.complexQuestions[leadingQuestionId].rows);

          const leadingQuestiondisplay = clauses.get(leadingQuestionId)?.display;

          rows.forEach(rowId => {
            const key = keyFromIds(leadingQuestionId, rowId, questionId);
            responses = updateResponse(responses, display, key);
            progress = updateProgress(progress, responses[key]);
            dirtyFields = { ...dirtyFields, [key]: true };

            // update parent question
            responses = updateComplexResponse(responses, clauses, progress, leadingQuestionId, leadingQuestiondisplay);
            dirtyFields = {
              ...dirtyFields,
              [leadingQuestionId]: true,
            };
            progress = updateProgress(progress, responses[leadingQuestionId]);
          });
        }
        if (kind === ClauseKind.Row && leadingQuestionId) {
          const leadingQuestiondisplay = clauses.get(leadingQuestionId)?.display;

          responses = updateComplexResponse(responses, clauses, progress, leadingQuestionId, leadingQuestiondisplay);
          dirtyFields = {
            ...dirtyFields,
            [leadingQuestionId]: true,
          };
          progress = updateProgress(progress, responses[leadingQuestionId]);
        }

        if (kind === ClauseKind.Question) {
          const isComplex = !isEmpty(progress.complexQuestions?.[questionId]);

          responses = isComplex
            ? updateComplexResponse(responses, clauses, progress, questionId, display)
            : updateResponse(responses, display, questionId);

          progress = updateProgress(progress, responses[questionId]);
          dirtyFields = { ...dirtyFields, [questionId]: true };
        }

        if (kind === ClauseKind.Option) {
          if (!optionId || display || !questionId) {
            return {
              responses,
              dirtyFields,
              clauses,
              progress,
            };
          }

          const key = leadingQuestionId && rowId ? keyFromIds(leadingQuestionId, rowId, questionId) : questionId;

          const { type, value, status } = processOptionResponse(responses[key], optionId);

          if (type === 'option' || type === 'options' || type === 'groupedOptions') {
            responses[key] = { ...responses[key], content: value, status };
            progress = updateProgress(progress, responses[key]);
            dirtyFields = { ...dirtyFields, [key]: true };
          }
          if (leadingQuestionId) {
            const leadingQuestiondisplay = clauses.get(leadingQuestionId)?.display;
            responses = updateComplexResponse(responses, clauses, progress, leadingQuestionId, leadingQuestiondisplay);
            progress = updateProgress(progress, responses[leadingQuestionId]);
            dirtyFields = { ...dirtyFields, [leadingQuestionId]: true };
          }
        }

        return {
          responses,
          dirtyFields,
          clauses,
          progress,
        };
      }),

    setClauseBatch: entries =>
      set(originalState => {
        if (entries.length === 0) return {};
        const responses = { ...originalState.responses };
        const dirtyFields = { ...originalState.dirtyFields };
        const clauses = new Map(originalState.clauses);
        let progress = { ...originalState.progress };
        for (const entry of entries) {
          const { key, value } = entry;
          const { leadingQuestionId, rowId, questionId, optionId, kind, display } = value;

          clauses.set(key, value);

          if (kind === ClauseKind.ChildQuestion && leadingQuestionId) {
            const key = keyFromIds(leadingQuestionId, rowId, questionId);

            const oldStatus = responses[key]?.status;
            updateResponseInPlace(responses, display, key);
            progress = updateProgress(progress, responses[key]);
            if (oldStatus !== responses[key]?.status) dirtyFields[key] = true;

            const leadingQuestiondisplay = clauses.get(leadingQuestionId)?.display;

            // update parent question
            const oldStatusLeading = responses[leadingQuestionId]?.status;
            updateComplexResponseInPlace(responses, clauses, progress, leadingQuestionId, leadingQuestiondisplay);
            if (oldStatusLeading !== responses[leadingQuestionId]?.status) dirtyFields[leadingQuestionId] = true;
            progress = updateProgress(progress, responses[leadingQuestionId]);
          }

          if (kind === ClauseKind.Column && leadingQuestionId) {
            const rows = Object.keys(progress.complexQuestions[leadingQuestionId].rows);

            const leadingQuestiondisplay = clauses.get(leadingQuestionId)?.display;
            for (const rowId of rows) {
              const key = keyFromIds(leadingQuestionId, rowId, questionId);
              const oldStatus = responses[key]?.status;
              updateResponseInPlace(responses, display, key);
              progress = updateProgress(progress, responses[key]);
              if (oldStatus !== responses[key]?.status) dirtyFields[key] = true;
            }
            // update parent question
            const oldStatusLeading = responses[leadingQuestionId]?.status;
            updateComplexResponseInPlace(responses, clauses, progress, leadingQuestionId, leadingQuestiondisplay);
            if (oldStatusLeading !== responses[leadingQuestionId]?.status) dirtyFields[leadingQuestionId] = true;
            progress = updateProgress(progress, responses[leadingQuestionId]);
          }
          if (kind === ClauseKind.Row && leadingQuestionId) {
            const leadingQuestiondisplay = clauses.get(leadingQuestionId)?.display;

            const oldStatus = responses[leadingQuestionId]?.status;
            updateComplexResponseInPlace(responses, clauses, progress, leadingQuestionId, leadingQuestiondisplay);
            if (oldStatus !== responses[leadingQuestionId]?.status) dirtyFields[leadingQuestionId] = true;
            progress = updateProgress(progress, responses[leadingQuestionId]);
          }

          if (kind === ClauseKind.Question) {
            const isComplex = !isEmpty(progress.complexQuestions?.[questionId]);

            const oldStatus = responses[questionId]?.status;
            if (isComplex) updateComplexResponseInPlace(responses, clauses, progress, questionId, display);
            else updateResponseInPlace(responses, display, questionId);

            progress = updateProgress(progress, responses[questionId]);
            if (oldStatus !== responses[questionId]?.status) dirtyFields[questionId] = true;
          }

          if (kind === ClauseKind.Option) {
            if (!optionId || display || !questionId) {
              continue;
            }

            const key = leadingQuestionId && rowId ? keyFromIds(leadingQuestionId, rowId, questionId) : questionId;

            const { type, value, status } = processOptionResponse(responses[key], optionId);

            if (type === 'option' || type === 'options' || type === 'groupedOptions') {
              const oldStatus = responses[key]?.status;
              responses[key] = { ...responses[key], content: value, status };
              progress = updateProgress(progress, responses[key]);
              if (oldStatus !== responses[key]?.status) dirtyFields[key] = true;
            }
            if (!rowId && leadingQuestionId) {
              // If all rows are selected, then CL will be applied to all rows. It is necessary to check each row to see if there is a CL that needs to be handled.
              const { sectionIndex, questionIndex } = findQuestionById(
                originalState.questionnaire?.sections as Section[],
                leadingQuestionId,
              );
              if (sectionIndex >= 0 && questionIndex >= 0) {
                const matrixQuestion = originalState.questionnaire?.sections[sectionIndex]?.questions[questionIndex];

                const allRowIds: string[] =
                  (matrixQuestion?.type as QuestionTypeMatrix)?.rows?.map(item => item.id) ?? [];
                for (const rowId of allRowIds) {
                  const key = keyFromIds(leadingQuestionId, rowId, questionId);

                  const { type, value, status } = processOptionResponse(responses[key], optionId);

                  if (type === 'option' || type === 'options' || type === 'groupedOptions') {
                    const oldStatus = responses[key]?.status;
                    responses[key] = { ...responses[key], content: value, status };
                    progress = updateProgress(progress, responses[key]);
                    if (oldStatus !== responses[key]?.status) dirtyFields[key] = true;
                  }
                }
              }
            }

            if (leadingQuestionId) {
              const leadingQuestiondisplay = clauses.get(leadingQuestionId)?.display;
              const oldStatus = responses[leadingQuestionId]?.status;
              updateComplexResponseInPlace(responses, clauses, progress, leadingQuestionId, leadingQuestiondisplay);
              progress = updateProgress(progress, responses[leadingQuestionId]);
              if (oldStatus !== responses[leadingQuestionId]?.status) dirtyFields[leadingQuestionId] = true;
            }
          }
        }
        return {
          responses,
          dirtyFields,
          clauses,
          progress,
        };
      }),

    setClauses: clauses =>
      set(state => {
        return {
          clauses: toClauses(clauses, state.idSet, state.dynamicRowMapping, state.staticRowMapping, state.clauses),
        };
      }),

    setClausesFetched: clauses => set(() => ({ clausesFetched: clauses })),
    setDisableCL: disableCL => set(() => ({ disableCL })),
    setIsCalculatingCL: isCalculatingCL => set(() => ({ isCalculatingCL })),
    setExpressionsFetched: expressions => set(() => ({ expressionsFetched: expressions })),

    setClausesPreview: (newClauses: any[]) =>
      set(state => ({
        ...state,
        clausesPreview: [
          ...state.clausesPreview,
          ...newClauses,
        ],
      })),

    setCurrentQuestion: (idx: number) => set(() => ({ currentQuestionIdx: idx })),

    setCurrentGuidanceQuestion: (idx: number) => set(() => ({ currentGuidanceQuestionIdx: idx })),

    setSaving: saving => set(() => ({ saving })),
    setLastSaved: date => set(() => ({ lastSaved: date })),

    resetDirtyFields: () => {
      set(state => ({
        dirtyFields: Object.keys(state.dirtyFields).reduce(
          (acc, key) => {
            if (state.dirtyFields[key]) acc[key] = true;
            return acc;
          },
          {} as Record<string, boolean>,
        ),
      }));
    },

    initStore: (questionnaire: Questionnaire) =>
      set(state => {
        const { sections, ...questionnaireDetails } = questionnaire;
        const filteredSections = sections.filter(section => section?.type !== SectionType.INFORMATION_BLOCK);
        const filteredQuestionnaire = { ...questionnaireDetails, sections: filteredSections };
        const { progress, idSet, dynamicRowMapping, staticRowMapping } = initializeStore(
          filteredQuestionnaire,
          state.progress,
        );
        return {
          progress,
          idSet,
          dynamicRowMapping,
          staticRowMapping,
        };
      }),

    setExpressions: data =>
      set(state => {
        return {
          expressions: toExpressions(data, state.idSet, state.dynamicRowMapping),
        };
      }),

    setExpressionsPreview: (newExpressions: Expression[]) =>
      set(state => ({
        ...state,
        expressionsPreview: [
          ...state.expressionsPreview,
          ...newExpressions,
        ],
      })),

    setExportQuestionnaire: (exportQuestionnaire: Partial<ExportQuestionnaire>) =>
      set(state => ({
        exportQuestionnaire: {
          ...state.exportQuestionnaire,
          ...exportQuestionnaire,
        },
      })),

    resetExportQuestionnaire: () =>
      set(() => ({
        exportQuestionnaire: {
          step: 0,
          format: ExportFormat.WORD,
          option: ExportOption.ALL,
          sections: new Set(),
          exporting: false,
        },
      })),

    nextSubmissionStep: (isPublic: boolean) =>
      set(state => {
        const nextStep = state.submission.step + 1;
        if (isPublic && nextStep === SubmissionStep.SUBMISSION_TYPE) {
          return {
            submission: {
              ...state.submission,
              step: state.submission.step + 2,
            },
          };
        } else {
          return {
            submission: {
              ...state.submission,
              step: ++state.submission.step,
            },
          };
        }
      }),
    prevSubmissionStep: (isPublic: boolean) =>
      set(state => {
        const prevStep = state.submission.step - 1;

        if (isPublic && prevStep === SubmissionStep.SUBMISSION_TYPE) {
          return {
            submission: {
              ...state.submission,
              step: state.submission.step - 2,
            },
          };
        } else {
          return {
            submission: {
              ...state.submission,
              step: --state.submission.step,
            },
          };
        }
      }),
    shareSubmission: () =>
      set(state => ({
        submission: {
          ...state.submission,
          isPublic: !state.submission.isPublic,
        },
      })),
    setIsToInternetConnected: (isToInternetConnected: boolean) =>
      set(() => ({
        isToInternetConnected: isToInternetConnected,
      })),

    cleanAfterRowDeletion: (leadinQuestionId: NumberOrString, rowIds: NumberOrString[]) =>
      set(state => {
        const { responses, clauses, expressions, progress, dirtyFields } = state;
        return {
          ...cleanAfterRowDeletion(leadinQuestionId, rowIds, responses, clauses, expressions, progress, dirtyFields),
        };
      }),

    updateAfterRowAddition: (leadinQuestionId: NumberOrString) =>
      set(state => {
        const { responses, progress, dirtyFields } = state;
        return {
          ...updateAfterRowAddition(leadinQuestionId, responses, progress, dirtyFields),
        };
      }),

    setLastChangesHash: (lastChangesHash: string) =>
      set(() => ({
        lastChangesHash,
      })),

    setImportGridErrors: (questionId: NumberOrString, errors: string[]) =>
      set(state => {
        const newError = new Map(state.importGridErrors);
        newError.set(questionId, errors);
        return {
          importGridErrors: newError,
        };
      }),
    setRequesterView: (requesterView: RequesterView) => set(() => ({ requesterView })),
  },
}));

// selectors
export const useQuestionnaire = () =>
  useResponseStore(state => {
    return {
      questionnaire: state.questionnaire as Questionnaire,
    };
  });

export const useQuestionnaireStatus = () =>
  useResponseStore(state => {
    return {
      isActive: state.questionnaire
        ? (state.questionnaire as Questionnaire).status === QuestionnaireStatus.ACTIVE
        : null,
    };
  });

export const useResponses = () => useResponseStore(state => state.responses);
export const useResponsesInitialized = () => useResponseStore(state => state.responsesInitialized);

export const useImportGridErrors = (questionId: string) =>
  useResponseStore(state => state.importGridErrors.get(questionId));

export const useIsTemplateAvailable = (questionId: string) =>
  useResponseStore(state => state.availableTemplates.has(questionId));

// dirty fields ( to be saved )

export const useDirtyFields = () => useResponseStore(state => state.dirtyFields);

// useErrorFields returns all field ids which have currently validation error
export const useErrorFields = () => useResponseStore(state => state.errorFields);

// uncompleted section
export const useSubmitValidation = (questionnaire: Questionnaire | undefined | null) =>
  useResponseStore(state => {
    const uncompleted: UncompletedSubmission[] = [];
    // True - if all mandatory questions are answered
    let canSubmit = true;

    // count unanswered mandatory questions
    const countMandatoryBySection = (questions: Question[]) => {
      return questions.reduce((mandatory, question) => {
        const available = state.clauses.get(question.id)?.display;
        if (
          question?.isMandatory &&
          [
            undefined,
            ResponseStatus.Unanswered,
            ResponseStatus.Progress,
          ].includes(state.responses[question.id]?.status)
        ) {
          canSubmit = false;
          return mandatory + 1;
        }
        if (
          available !== false &&
          isMatrixTableAndMandatoryColumnsAreNotFullyAnswered(
            state.clauses,
            state.expressions,
            state.responses,
            question,
          )
        ) {
          canSubmit = false;
          return mandatory + 1;
        }
        return mandatory;
      }, 0);
    };

    const isUncomplete = (progress: Progress) => {
      if (progress.skippedQuestions.size) return true;

      return (
        progress?.reviewedQuestions.size + progress?.answeredQuestions.size !==
        progress?.totalQuestions - progress.conditionalQuestions.size
      );
    };

    questionnaire?.sections?.forEach(section => {
      // include section if:
      // 1) at least one question is unanswered
      // 1) at least one skipped question
      if (section) {
        const progress = state.progress.sections[section.id];
        if (progress && isUncomplete(progress)) {
          uncompleted.push({
            section,
            progress: {
              answered: progress.answeredQuestions.size,
              reviewed: progress.reviewedQuestions.size,
              started: progress.startedQuestions.size,
              skipped: progress.skippedQuestions.size,
              conditional: progress.conditionalQuestions.size,
              total: progress.totalQuestions,
            },
            mandatory: countMandatoryBySection(section?.questions as Question[]),
          });
        }
      }
    });
    return { uncompleted, canSubmit };
  });

// section
export const useCurrentSectionIdx = () => useResponseStore(state => state.currentSectionIdx);
// question
export const useCurrentQuestionIdx = () => useResponseStore(state => state.currentQuestionIdx);
export const useCurrentGuidanceQuestionIdx = () => useResponseStore(state => state.currentGuidanceQuestionIdx);
// question response
export const useQuestionResponse = (ids: Ids) =>
  useResponseStore<QuestionResponse | undefined>(state => {
    // don't show responses in guidance mode
    if (state.isGuidance) return undefined;

    const { leadingQuestionId, rowId, questionId } = ids;
    return state.responses?.[keyFromIds(leadingQuestionId, rowId, questionId)];
  });

// progress
export const useProgress = () => useResponseStore(state => state.progress);

export const useOverallProgress = () =>
  useResponseStore(state => {
    const questionnaire = initProgressPoints();

    for (const key in state.progress.sections) {
      const section = state.progress.sections[key];
      questionnaire.answered += section?.answeredQuestions.size ?? 0;
      questionnaire.reviewed += section?.reviewedQuestions.size ?? 0;
      questionnaire.started += section?.startedQuestions.size ?? 0;
      questionnaire.skipped += section?.skippedQuestions.size ?? 0;
      questionnaire.conditional += section?.conditionalQuestions.size ?? 0;
      questionnaire.total += section?.totalQuestions ?? 0;
    }

    return questionnaire;
  });

export const useSectionCompletenessProgress = () =>
  useResponseStore(state => {
    let completedSectionNumbers = 0;
    const totalSectionNumbers = Object.keys(state.progress?.sections)?.filter(key => key !== 'undefined')?.length;

    for (const key in state.progress.sections) {
      if (key !== 'undefined') {
        const section = state.progress.sections[key];
        const allAnswered =
          (section?.answeredQuestions.size ?? 0) +
          (section?.reviewedQuestions.size ?? 0) +
          (section?.skippedQuestions.size ?? 0);
        const total = (section?.totalQuestions ?? 0) - (section?.conditionalQuestions.size ?? 0);
        allAnswered === total && completedSectionNumbers++;
      }
    }

    return { completedSectionNumbers, totalSectionNumbers };
  });

export const useSectionProgress = (sectionId: NumberOrString) =>
  useResponseStore(state => {
    if (!state.progress.sections[sectionId]) {
      return {
        ...initProgressPoints(),
        status: ProgressStatus.NotStarted,
      };
    }

    const section = state.progress.sections[sectionId];

    const answered = section?.answeredQuestions.size ?? 0;
    const reviewed = section?.reviewedQuestions.size ?? 0;
    const skipped = section?.skippedQuestions.size ?? 0;
    const started = section?.startedQuestions.size ?? 0;
    const conditional = section?.conditionalQuestions.size ?? 0;
    const total = section?.totalQuestions ?? 0;

    const status =
      answered + reviewed + started + skipped == 0
        ? ProgressStatus.NotStarted
        : answered + reviewed + skipped == total - conditional
          ? ProgressStatus.Completed
          : ProgressStatus.Progress;

    return { status, total, answered, reviewed, skipped, started, conditional };
  });

export const useComplexProgress = (questionId: NumberOrString) =>
  useResponseStore(state => {
    if (state.progress.complexQuestions[questionId]) {
      return getComplexQuestionStatus(questionId, state.progress.complexQuestions, state.clauses);
    }
    return {
      totalRows: 0,
      completedRows: 0,
      status: ResponseStatus.Unanswered,
    };
  });

export const useClauses = () => useResponseStore(state => state.clauses);

export const useClausesFetched = () => useResponseStore(state => state.clausesFetched);
export const useExpressionsFetched = () => useResponseStore(state => state.expressionsFetched);
export const useDisableCL = () => useResponseStore(state => state.disableCL);
export const useIsCalculatingCL = () => useResponseStore(state => state.isCalculatingCL);

export const useClausesPreview = () => useResponseStore(state => state.clausesPreview);

export const useClauseByIds = (ids: Ids) =>
  useResponseStore(state => {
    const { leadingQuestionId, rowId, questionId } = ids;
    const key = keyFromIds(leadingQuestionId, rowId, questionId);

    if (state.clauses.has(key)) return state.clauses.get(key);

    return null;
  });

export const useRowProgress = (questionId: NumberOrString, rowId: NumberOrString) =>
  useResponseStore(state => {
    let totalQuestions = 0;
    let completedQuestions = 0;
    let conditional = 0;

    const { progress } = state;
    if (!progress.complexQuestions[questionId]?.rows?.[rowId])
      return { totalQuestions, completedQuestions, conditional };

    totalQuestions = progress.complexQuestions[questionId].rows[rowId].totalQuestions;
    completedQuestions =
      progress.complexQuestions[questionId].rows[rowId].answeredQuestions.size +
      progress.complexQuestions[questionId].rows[rowId].reviewedQuestions.size +
      progress.complexQuestions[questionId].rows[rowId].skippedQuestions.size;
    conditional = progress.complexQuestions[questionId].rows[rowId].conditionalQuestions.size;

    return {
      totalQuestions,
      completedQuestions,
      conditional,
    };
  });

// currencies
export const useDefaultCurrency = () => useResponseStore(state => state.defaultCurrency);
// lastSaved
export const useLastSaved = () => useResponseStore(state => state.lastSaved);
// saving
export const useSaving = () => useResponseStore(state => state.saving);
//lastChangesHash
export const useLastChangesHash = () => useResponseStore(state => state.lastChangesHash);
// question
export const useDisplayQuestion = (questionId: NumberOrString) =>
  useResponseStore(state => {
    const available = state.isGuidance ? undefined : state.clauses.get(questionId)?.display;
    const status = state.isGuidance ? ResponseStatus.Unanswered : state.responses[questionId]?.status;
    return {
      available,
      status,
    };
  });
// auto calc
export const useIsAutoCalcCellsAnswered = (leadingQuestionId: NumberOrString, rows: Row[], columns: Question[]) =>
  useResponseStore(state =>
    checkAllAnswered(leadingQuestionId, rows, columns, state.responses, state.expressions, state.clauses),
  );

export const useDisplayChildQuestion = (
  leadingQuestionId: NumberOrString,
  rowId: NumberOrString,
  questionId: NumberOrString,
) =>
  useResponseStore(state => {
    const key = keyFromIds(leadingQuestionId, rowId, questionId);
    return {
      available: state.clauses.get(key)?.display,
      status: state.responses[key]?.status,
    };
  });

// question row
export const useDisplayRows = (leadingQuestionId: NumberOrString, rows: Row[]) =>
  useResponseStore(state => {
    const filtered = rows.filter(row => state.clauses.get(keyFromIds(leadingQuestionId, row.id))?.display !== false);

    return {
      rows: sortBy(filtered, 'order'),
    };
  });

export const useDisplayColumns = (leadingQuestionId: NumberOrString, columns: Question[]) =>
  useResponseStore(state => {
    const filtered = columns.filter(
      column => state.clauses.get(keyFromIds(leadingQuestionId, '*', column.id))?.display !== false,
    );

    return {
      columns: sortBy(filtered, 'order'),
    };
  });

// export questionnaire
export const useExportQuestionnaire = () =>
  useResponseStore(state => ({
    exportQuestionnaire: state.exportQuestionnaire,
  }));

/**
 *
 * submission
 */

export const useSubmission = () =>
  useResponseStore(state => {
    return state.submission;
  });

/**
 *
 *  one selector for all our actions
 */
export const useResponseActions = () => useResponseStore(state => state.actions);

export const useRequestingAuthorities = () => useResponseStore(state => state.requestingAuthorities);

export const useQuestionsNumbers = () => useResponseStore(state => state.questionsNumbers);

export const useHasExpression = (ids: Ids) =>
  useResponseStore(state => {
    const { leadingQuestionId, rowId, questionId } = ids;
    const key = keyFromIds(leadingQuestionId, rowId, questionId);
    return state.expressions.has(key);
  });

export const useExpression = (ids: Ids, blankOptions: number = -1) =>
  useResponseStore(state => {
    const { leadingQuestionId, rowId, questionId } = ids;
    const key = keyFromIds(leadingQuestionId, rowId, questionId);
    return evaluatePostfixExpression(state.responses, state.expressions.get(key), state.clauses, blankOptions);
  });

export const useCrossValidation = (ids: Ids) =>
  useResponseStore(state => {
    const { leadingQuestionId, rowId, questionId } = ids;
    const key = keyFromIds(leadingQuestionId, rowId, questionId);
    const validation = state.crossValidation.get(key);
    const response = state.responses?.[key]?.content;
    return evaluateCrossValidation(state.responses, validation, response);
  });

export const useExpressions = () => useResponseStore(state => state.expressions);

export const useMetadataFetched = () => useResponseStore(state => state.metadata);

export const useExpressionsPreview = () => useResponseStore(state => state.expressionsPreview);

export const useIsToInternetConnected = () => useResponseStore(state => state.isToInternetConnected);

export const useIsSkipSave = () => useResponseStore(state => state.skipSave);

export const useIsGuidance = () => useResponseStore(state => state.isGuidance);

export const useBlocksByQuestion = (id: string) =>
  useResponseStore(state => {
    if (state.blocksByQuestion.has(id)) return state.blocksByQuestion.get(id);
    return null;
  });

export const useColumnsBlocks = (question: Question) => {
  const columns = (question.type as QuestionTypeMatrix)?.columns ?? [];
  return useResponseStore(state => {
    return columns.reduce(
      (acc, column) => {
        acc[column.id] = state.blocksByQuestion.get(column.id) ?? null;
        return acc;
      },
      {} as Record<string, ContentBlock[] | null>,
    );
  });
};

export const useDynamicRowMapping = () => useResponseStore(state => state.dynamicRowMapping);

/**
 * options / grouped options
 */

export const useOptions = (options: Option[], ids: Ids) =>
  useResponseStore(state => {
    const { leadingQuestionId, rowId, questionId } = ids;
    return options.filter(option => {
      if (state.isGuidance) return true;

      const cellLevelKey = keyFromIds(leadingQuestionId ?? '*', rowId ?? '*', questionId, option.id);
      const questionLevelKey = keyFromIds(leadingQuestionId ?? '*', '*', questionId, option.id);

      if (!state.clauses.has(questionLevelKey) && !state.clauses.has(cellLevelKey)) return true;

      const displayInMatrix = state.clauses.get(questionLevelKey)?.display;
      const displayInCell = state.clauses.get(cellLevelKey)?.display;

      return displayInCell || displayInMatrix;
    });
  });

export const useGroupedOptions = (groupedOptions: GroupedOption[], ids: Ids) =>
  useResponseStore(state => {
    const { leadingQuestionId, rowId, questionId } = ids;

    return groupedOptions
      .map(group => {
        const options = group.options.filter(option => {
          if (state.isGuidance) return true;

          const cellLevelKey = keyFromIds(leadingQuestionId ?? '*', rowId ?? '*', questionId, option.id);
          const questionLevelKey = keyFromIds(leadingQuestionId ?? '*', '*', questionId, option.id);

          if (!state.clauses.has(questionLevelKey) && !state.clauses.has(cellLevelKey)) return true;

          const displayInMatrix = state.clauses.get(questionLevelKey)?.display;
          const displayInCell = state.clauses.get(cellLevelKey)?.display;

          return displayInCell || displayInMatrix;
        });

        return { ...group, options };
      })
      .filter(go => go.options.length > 0);
  });

export const useQuestionnaireVersion = () =>
  useResponseStore(state => {
    return {
      version: state.questionnaire?.version,
    };
  });

export const useMatrixTableQuestionVisibleColumnsAndRows = (question: Question): { rows: Row[]; columns: Question[] } =>
  useResponseStore(state => {
    if (!isQuestionTypeMatrix(question.type)) {
      return {
        rows: [],
        columns: [],
      };
    }
    if (!isMatrixTableVariant(question.type)) {
      return {
        rows: [],
        columns: [],
      };
    } else {
      return {
        columns: getVisibleColumns(state.clauses, question.id, question.type.columns),
        rows: getVisibleRows(state.clauses, question.id, question.type.rows),
      };
    }
  });

export const useRequesterView = () => useResponseStore(state => state.requesterView);
